<template>
  <div>
    <el-form :inline="true" :model="filter" size="mini">
      <el-form-item label="开始日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.begin"
          type="date"
          placeholder="选择日期"
          style="width:150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结束日期">
        <el-date-picker
          value-format="yyyy-MM-dd"
          v-model="filter.end"
          type="date"
          placeholder="选择日期"
          style="width:150px"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="项目">
        <project-select v-model="filter.projectId"></project-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getData" icon="el-icon-refresh">刷新</el-button>
        <el-button type="success" @click="excel">
          <span class="iconfont iconexcel" style="font-size:12px;"></span>
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <pl-table
      :data="data"
      border
      stripe
      :height="tableHeight"
      :row-height="rowHeight"
      use-virtual
      ref="table"
      @row-dblclick="dblclickTable"
      :summary-method="getSummaries"
      show-summary
    >
      <el-table-column
        label="项目"
        prop="projectName"
        width="200"
        show-overflow-tooltip
        sortable
        fixed="left"
      ></el-table-column>
      <el-table-column label="预付">
        <el-table-column label="期初" prop="repayStart" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.repayStart|thousands}}</template>
        </el-table-column>
        <el-table-column label="本期" prop="repayAmount" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.repayAmount|thousands}}</template>
        </el-table-column>
        <el-table-column label="期末" prop="repayEnd" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.repayEnd|thousands}}</template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="物资">
        <el-table-column label="期初" prop="goodsStart" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.goodsStart|thousands}}</template>
        </el-table-column>
        <el-table-column label="本期" prop="goodsAmount" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.goodsAmount|thousands}}</template>
        </el-table-column>
        <el-table-column label="期末" prop="goodsEnd" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.goodsEnd|thousands}}</template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="费用">
        <el-table-column label="期初" prop="expenseStart" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.expenseStart|thousands}}</template>
        </el-table-column>
        <el-table-column label="本期" prop="expenseAmount" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.expenseAmount|thousands}}</template>
        </el-table-column>
        <el-table-column label="期末" prop="expenseEnd" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.expenseEnd|thousands}}</template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="人工">
        <el-table-column label="期初" prop="laborStart" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.laborStart|thousands}}</template>
        </el-table-column>
        <el-table-column label="本期" prop="laborAmount" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.laborAmount|thousands}}</template>
        </el-table-column>
        <el-table-column label="期末" prop="laborEnd" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.laborEnd|thousands}}</template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="结算">
        <el-table-column label="期初" prop="receiptStart" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.receiptStart|thousands}}</template>
        </el-table-column>
        <el-table-column label="本期" prop="receiptAmount" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.receiptAmount|thousands}}</template>
        </el-table-column>
        <el-table-column label="期末" prop="receiptEnd" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.receiptEnd|thousands}}</template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="收支合计">
        <el-table-column label="期初" prop="totalStart" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.totalStart|thousands}}</template>
        </el-table-column>
        <el-table-column label="本期" prop="totalAmount" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.totalAmount|thousands}}</template>
        </el-table-column>
        <el-table-column label="期末" prop="totalEnd" :width="$g.tableAmountWidth">
          <template slot-scope="scope">{{scope.row.totalEnd|thousands}}</template>
        </el-table-column>
      </el-table-column>
    </pl-table>
  </div>
</template>

<script>
import ProjectSelect from "@/components/ProjectSelect.vue";
import setName from "@/common/setName";
import tableHeight from "@/common/tableHeightMixins";
import toExcel from "@/common/toExcel";
export default {
  mixins: [tableHeight],
  components: {
    ProjectSelect
  },
  data() {
    return {
      filter: {
        begin: this.$moment()
          .subtract(1, "months")
          .format("YYYY-MM-DD"),
        end: this.$moment().format("YYYY-MM-DD")
      },
      data: []
    };
  },
  activated() {
    this.getData();
  },
  methods: {
    getData() {
      if (!this.filter.begin) {
        this.$message.error("请选择开始日期");
        return;
      }
      if (!this.filter.end) {
        this.$message.error("请选择结束日期");
        return;
      }
      let params = { ...this.filter };
      let loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$get("ProjectReceiptPayment/Summary", params)
        .then(r => {
          r.forEach(item => {
            setName("Project", item, "projectId", "projectName");
            item.repayEnd = this.amountFormat(
              item.repayStart + item.repayAmount
            );
            item.goodsEnd = this.amountFormat(
              item.goodsStart + item.goodsAmount
            );
            item.expenseEnd = this.amountFormat(
              item.expenseStart + item.expenseAmount
            );
            item.laborEnd = this.amountFormat(
              item.laborStart + item.laborAmount
            );
            item.receiptEnd = this.amountFormat(
              item.receiptStart + item.receiptAmount
            );
            item.totalAmount = this.amountFormat(
              item.receiptAmount -
                item.repayAmount -
                item.goodsAmount -
                item.expenseAmount -
                item.laborAmount
            );
            item.totalStart = this.amountFormat(
              item.receiptStart -
                item.repayStart -
                item.goodsStart -
                item.expenseStart -
                item.laborStart
            );
            item.totalEnd = this.amountFormat(
              item.receiptEnd -
                item.repayEnd -
                item.goodsEnd -
                item.expenseEnd -
                item.laborEnd
            );
          });
          this.data = r;
        })
        .finally(() => {
          loading.close();
        });
    },
    getSummaries({ columns, data }) {
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          let total = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = this.thousandsFormat(this.amountFormat(total));
        } else {
          sums[index] = "-";
        }
      });

      return [sums];
    },
    dblclickTable(row) {
      let permission = "";

      if (this.hasPermission("ProjectReceiptPaymentDetailByBill")) {
        permission = "ProjectReceiptPaymentDetailByBill";
      } else if (this.hasPermission("ProjectReceiptPaymentDetailByDetail")) {
        permission = "ProjectReceiptPaymentDetailByDetail";
      }

      if (permission) {
        this.$store.commit("setFilterCache", {
          name: permission,
          filter: {
            begin: this.filter.begin,
            end: this.filter.end,
            projectId: row.projectId
          }
        });
        this.$router.push({
          name: permission
        });
      }
    },
    excel() {
      let list = [];
      this.data.forEach(item => {
        list.push({
          projectName: item.projectName,
          repayStart: this.amountFormat(item.repayStart),
          repayAmount: this.amountFormat(item.repayAmount),
          repayEnd: this.amountFormat(item.repayEnd),
          goodsStart: this.amountFormat(item.goodsStart),
          goodsAmount: this.amountFormat(item.goodsAmount),
          goodsEnd: this.amountFormat(item.goodsEnd),
          expenseStart: this.amountFormat(item.expenseStart),
          expenseAmount: this.amountFormat(item.expenseAmount),
          expenseEnd: this.amountFormat(item.expenseEnd),
          laborStart: this.amountFormat(item.laborStart),
          laborAmount: this.amountFormat(item.laborAmount),
          laborEnd: this.amountFormat(item.laborEnd),
          receiptStart: this.amountFormat(item.receiptStart),
          receiptAmount: this.amountFormat(item.receiptAmount),
          receiptEnd: this.amountFormat(item.receiptEnd),
          totalStart: this.amountFormat(item.totalStart),
          totalAmount: this.amountFormat(item.totalAmount),
          totalEnd: this.amountFormat(item.totalEnd)
        });
      });
      let header = [
        [
          "项目",
          "预付",
          "",
          "",
          "物资",
          "",
          "",
          "费用",
          "",
          "",
          "人工",
          "",
          "",
          "结算",
          "",
          "",
          "收支合计",
          "",
          ""
        ],
        [
          "",
          "期初",
          "本期",
          "期末",
          "期初",
          "本期",
          "期末",
          "期初",
          "本期",
          "期末",
          "期初",
          "本期",
          "期末",
          "期初",
          "本期",
          "期末",
          "期初",
          "本期",
          "期末"
        ]
      ];
      let column = [
        "projectName",
        "repayStart",
        "repayAmount",
        "repayEnd",
        "goodsStart",
        "goodsAmount",
        "goodsEnd",
        "expenseStart",
        "expenseAmount",
        "expenseEnd",
        "laborStart",
        "laborAmount",
        "laborEnd",
        "receiptStart",
        "receiptAmount",
        "receiptEnd",
        "totalStart",
        "totalAmount",
        "totalEnd"
      ];
      toExcel(header, column, list, "项目收支汇总", [
        {
          s: {
            c: 0, //开始列
            r: 0 //开始行
          },
          e: {
            c: 0, //结束列
            r: 1 //结束行
          }
        },
        {
          s: {
            c: 1, //开始列
            r: 0 //开始行
          },
          e: {
            c: 3, //结束列
            r: 0 //结束行
          }
        },
        {
          s: {
            c: 4, //开始列
            r: 0 //开始行
          },
          e: {
            c: 6, //结束列
            r: 0 //结束行
          }
        },
        {
          s: {
            c: 7, //开始列
            r: 0 //开始行
          },
          e: {
            c: 9, //结束列
            r: 0 //结束行
          }
        },
        {
          s: {
            c: 10, //开始列
            r: 0 //开始行
          },
          e: {
            c: 12, //结束列
            r: 0 //结束行
          }
        },
        {
          s: {
            c: 13, //开始列
            r: 0 //开始行
          },
          e: {
            c: 15, //结束列
            r: 0 //结束行
          }
        },
        {
          s: {
            c: 16, //开始列
            r: 0 //开始行
          },
          e: {
            c: 18, //结束列
            r: 0 //结束行
          }
        }
      ]);
    }
  }
};
</script>

<style>
</style>